
import { defineComponent, PropType, reactive, toRefs, ref, h } from "vue";
import pagaTable from "@/components/pagination-table/index";
import { TransformCellTextProps } from "ant-design-vue/lib/table/interface";
import formSearch from "@/components/page-search/form";
import { VisitorControllerPage } from "@/api/api"
import { VisitorControllerDownload } from '@/api/downloadApi'
import {blob} from '@/utils/blob'
import { message } from 'ant-design-vue';

const purchaseColumns = [
  {
    title: "序号",
    width: 60,
    dataIndex: "index",
    customRender: (row: TransformCellTextProps) => row.index + 1,
  },
  {
    title: "身份证号 ",
    dataIndex: "idCard",
  },
  {
    title: "姓名",
    dataIndex: "name",
  },

  {
    title: "手机号",
    dataIndex: "phone",
  },
  {
    title: "来访事由",
    dataIndex: "type",
    customRender: (text: TransformCellTextProps) => (text.text === 1 ? "进厂施工" : (text.text === 2 ? "进厂送货" : "业务沟通")),
  },
  {
    title: "是否完成培训",
    dataIndex: "status",
    customRender: (text: TransformCellTextProps) => (text.text === 0 ? "否" : "是"),

  },
  {
    title: "考题得分",
    dataIndex: "score",
  },
  
  {
    title: "来访时间",
    dataIndex: "createdTime",
    sorter: true,
  },
];
export default defineComponent({
  name: "questionType",
  components: {
    pagaTable,
    formSearch,
  },
  setup(props: any) {
    const data = reactive({
      purchaseColumns,
      selectedRowKeys: [],
      extraModel: {},
      formBtn: {
        colProps: { span: 16 },
        formItemProps: {
          wrapperCol: {
            span: 24,
          },
        },
      },
      formConfig: [
        //试题描述：
        {
          colProps: {
            span: 8,
          },
          formItemProps: {
            label: "身份证号：",
          },
          comConfig: {
            tag: "input", // input, select, date-picker
            key: "idCard", // 即是插槽也是字段model key
            props: {
              placeholder: "请输入",
            },
          },
        },
        {
          colProps: {
            span: 8,
          },
          formItemProps: {
            label: "访客姓名",
          },
          comConfig: {
            tag: "input", // input, select, date-picker
            key: "name", // 即是插槽也是字段model key
            props: {
              placeholder: "请输入",
            },
          },
        },
        {
          colProps: {
            span: 8,
          },
          formItemProps: {
            label: "手机号码",
          },
          comConfig: {
            tag: "input", // input, select, date-picker
            key: "phone", // 即是插槽也是字段model key
            props: {
              placeholder: "请输入",
            },
          },
        },
        {
          rowProps: {},
          colProps: {
            span: 8,
          },
          formItemProps: {
            label: "来访时间",
          },
          comConfig: {
            tag: "input",
            key: "testSlots",
            props: {
              placeholder: "请输入",
            },
          },
        },
      ],
    });
    const onSelectChange = (selectedRowKeys: never[]) => {
      data.selectedRowKeys = selectedRowKeys;
    };
    const table = ref();
    const params_api = ref()
    const getDataApi = (params: any) => {
      return new Promise((reslove) => {
        params_api.value = {
          "currentPage": params.pageIndex,
          "pageSize": params.pageSize,
          ...formSearch.value.getQuery(),
          'createdTime': updatedTime.value,
          sort: params.sort
        }
        VisitorControllerPage({ ...params_api.value}).then(res => {
          reslove(res)
        })
      });
    };
    function handleDownload() {
      VisitorControllerDownload({ ...params_api.value }).then(res => {
        blob(res)
      })
    }
    function downloadSelectApi() {
      if(data.selectedRowKeys.length>0){
        console.log(data.selectedRowKeys);
        message.warning('当前数据为统计数据，无法单独导出，请点击全部导出');
      }else{
        message.warning('请先选择需要下载的内容');
      }
    }
    const handleChange = (
      pagination: any,
      filter: any,
      sorter: any,
      { currentDataSource }: any
    ) => {
      // console.log(sorter);
    };
    const handleClick = (text: string, row: any) => {
      Title.value = "查询考卷";
      popupWindow.value.showModal();
    };
    const formSearch = ref();
    const popupWindow = ref();
    const Title = ref();
    const updatedTime = ref([]);
    const handleSearch = (formState: any) => {
      table.value.refresh();
    };
    const reset = () => {
      updatedTime.value = []
    };
  
    return {
      reset,
      handleDownload,
      ...toRefs(data),
      table,
      onSelectChange,
      getDataApi,
      handleChange,
      handleClick,
      formSearch,
      popupWindow,
      Title,
      handleSearch,
      updatedTime,
    };
  },
});
