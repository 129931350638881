import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page-wrapper question-type" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_range_picker = _resolveComponent("a-range-picker")!
  const _component_formSearch = _resolveComponent("formSearch")!
  const _component_pagaTable = _resolveComponent("pagaTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_formSearch, {
      ref: "formSearch",
      formConfig: _ctx.formConfig,
      onSearch: _ctx.handleSearch,
      formBtn: _ctx.formBtn,
      onReset: _ctx.reset
    }, {
      testSlots: _withCtx((comConfig) => [
        _createVNode(_component_a_range_picker, {
          value: _ctx.updatedTime,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.updatedTime) = $event)),
          "value-format": "YYYY-MM-DD"
        }, null, 8, ["value"])
      ]),
      _: 1
    }, 8, ["formConfig", "onSearch", "formBtn", "onReset"]),
    _createVNode(_component_pagaTable, {
      ref: "table",
      columns: _ctx.purchaseColumns,
      sourceData: _ctx.getDataApi,
      "row-key": "visitorId",
      onChange: _ctx.handleChange,
      downloadApi: _ctx.handleDownload,
      downloadSelectApi: _ctx.downloadSelectApi
    }, null, 8, ["columns", "sourceData", "onChange", "downloadApi", "downloadSelectApi"])
  ]))
}